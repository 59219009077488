var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "background"
  }, [_c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header')])], 2), _c('div', {
    staticClass: "background-header position-relative w-100 z-index-1 mt-5",
    staticStyle: {
      "background": "linear-gradient(to bottom, #cce8ed, #ffffff)",
      "height": "250px"
    }
  }, [_c('div', {
    staticClass: "container py-5"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "text-dark display-4 mb-2",
    staticStyle: {
      "font-size": "2.5rem",
      "font-weight": "bold"
    }
  }, [_vm._v("Temukan Peluang Belajar ")]), _c('p', {
    staticClass: "text-dark h4"
  }, [_vm._v("Menarik dan Raih Tujuan Akademik Anda!")])])])]), _c('div', {
    staticClass: "background"
  }, [!_vm.loading ? _c('div', {
    staticClass: "card-section"
  }, [_c('div', {
    staticClass: "container px-4 px-md-5"
  }, [_c('div', {
    staticClass: "card-block bg-white rounded border"
  }, [_c('div', {
    staticClass: "container section-title p-4"
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_vm._v("Paket Pembelajaran")]), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("Pilih Paket Bimbel & Try Out yang Tepat untuk Kesuksesanmu.")])]), _c('div', {
    staticClass: "py-1 px-0 px-md-4"
  }, [_c('div', {
    staticClass: "row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3"
  }, _vm._l(_vm.data, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "col",
      on: {
        "click": function click($event) {
          return _vm.detail(item);
        }
      }
    }, [_c('div', {
      staticClass: "card mx-auto shadow-lg rounded-3"
    }, [_c('div', {
      staticClass: "position-relative overflow-hidden rounded-top-3"
    }, [_c('img', {
      staticClass: "card-img-top img-fluid",
      attrs: {
        "src": "https://api-ecourse.digma.id/".concat(item.thumbnail),
        "alt": "Thumbnail"
      }
    })]), _c('div', {
      staticClass: "card-details card-body"
    }, [_c('p', {
      staticClass: "text-black font-weight-bold"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "d-flex align-items-center mb-2"
    }, [_c('i', {
      staticClass: "fa-regular fa-user text-black"
    }), _c('span', {
      staticClass: "text-black ms-1"
    }, [_vm._v("150")]), _c('i', {
      staticClass: "fa-regular fa-clock text-black ml-4"
    }), _c('span', {
      staticClass: "text-black ms-1"
    }, [_vm._v(" 40m")])]), _c('hr'), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "text-black font-weight-bold"
    }, [_vm._v("Rp " + _vm._s(item.price.toLocaleString('id-ID')))])])]), _c('button', {
      staticClass: "card-button btn btn-outline-primary btn-sm"
    }, [_vm._v("Beli")])])]);
  }), 0)])])])]) : _vm._e()]), !_vm.loading ? _c('footer-home') : _vm._e()], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }