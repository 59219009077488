<template>
  <div class="background">
    <div id="user-profile">
      <b-overlay :show="loading">
        <b-navbar class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed">
          <slot name="navbar">
            <profile-header />
          </slot>
        </b-navbar>

        <!-- Background Header -->
        <div class="background-header position-relative w-100 z-index-1 mt-5"
          style="background: linear-gradient(to bottom, #cce8ed, #ffffff); height: 250px;">
          <div class="container py-5">
            <div class="text-center">
              <p class="text-dark display-4 mb-2" style="font-size: 2.5rem; font-weight: bold;">Temukan Peluang Belajar
              </p>
              <p class="text-dark h4">Menarik dan Raih Tujuan Akademik Anda!</p>
            </div>
          </div>
        </div>

        <!-- news -->
        <div class="background">
          <div v-if="!loading" class="card-section">
            <div class="container px-4 px-md-5">
              <div class="card-block bg-white rounded border">
                <div class="container section-title p-4">
                  <h2 class="text-center">Paket Pembelajaran</h2>
                  <p class="text-center">Pilih Paket Bimbel & Try Out yang Tepat untuk Kesuksesanmu.</p>
                </div>
                <div class="py-1 px-0 px-md-4">
                  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
                    <!-- Loop untuk menampilkan setiap paket -->
                    <div class="col" v-for="(item, i) in data" :key="i" @click="detail(item)">
                      <div class="card mx-auto shadow-lg rounded-3">
                        <!-- Gambar paket -->
                        <div class="position-relative overflow-hidden rounded-top-3">
                          <img :src="`https://api-ecourse.digma.id/${item.thumbnail}`" class="card-img-top img-fluid"
                            alt="Thumbnail" />
                        </div>

                        <!-- Detail kartu -->
                        <div class="card-details card-body">
                          <p class="text-black font-weight-bold">{{ item.name }}</p>
                          <div class="d-flex align-items-center mb-2">
                            <i class="fa-regular fa-user text-black"></i>
                            <span class="text-black ms-1">150</span>
                            <i class="fa-regular fa-clock text-black ml-4"></i>
                            <span class="text-black ms-1"> 40m</span>
                          </div>
                          <hr>
                          <div class="d-flex align-items-center">
                            <span class="text-black font-weight-bold">Rp {{ item.price.toLocaleString('id-ID') }}</span>
                          </div>
                        </div>

                        <!-- Tombol aksi -->
                        <button class="card-button btn btn-outline-primary btn-sm">Beli</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <footer-home v-if="!loading" />
      </b-overlay>
    </div>
  </div>
</template>

<script>
  import {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
  } from "bootstrap-vue";

  import ProfileHeader from "./components/Navbar.vue";
  import FooterHome from "./components/Footer.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  /* eslint-disable global-require */
  export default {
    components: {
      BOverlay,
      BRow,
      BCol,
      BCard,
      BCardText,
      BCardTitle,
      BButton,
      BLink,
      BBreadcrumb,
      BBreadcrumbItem,
      FooterHome,
      ProfileHeader,
    },
    data() {
      return {
        data: [],
        loading: true,
      };
    },

    methods: {
      getColor(color) {
        let warna = "";
        if (color == "primary") {
          warna = "blue";
        } else if (color == "success") {
          warna = "green";
        } else if (color == "danger") {
          warna = "red";
        } else if (color == "info") {
          warna = "light-blue";
        } else if (color == "warning") {
          warna = "yellow";
        }
        return warna;
      },
      getData() {
        let params = {
          orderCol: "price",
          order: "asc",
        };
        this.$store
          .dispatch("paketTryout/index", params)
          .then((res) => {
            this.data = res.data.data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.error("Error:", err);
          });
      },
      detail(item) {
        window.location = `paket-detail/${item.slug}`;
      },
    },
    created() {
      this.getData();
    },
  };
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

  * {
    font-family: "Open Sans", sans-serif;
  }

  .background {
    background: white;
  }

  .card {
    border-radius: 20px;
    background: #f5f5f5;
    padding: 1rem;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .card-img-top {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }

  .card-details {
    color: black;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    flex-grow: 1;
  }

  .card-button {
    transform: translate(-50%, 125%);
    width: 40%;
    border: none;
    background-color: #008bf8;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
  }

  .card:hover {
    border-color: #008bf8;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
  }

  .card:hover .card-button {
    transform: translate(-50%, 50%);
    opacity: 1;
    bottom: 0;
  }

  .text-title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .d-flex {
    gap: 0.5rem;
  }

  hr {
    margin: 0.5rem 0;
  }

  @media (max-width: 768px) {
    .background-header {
      height: 200px;
    }

    .text-title {
      font-size: 1.5rem;
    }

    .card-button {
      font-size: 0.875rem;
      width: auto;
    }
  }

  @media (max-width: 576px) {
    .background-header {
      height: 150px;
    }

    .text-title {
      font-size: 1.2rem;
    }

    .card-button {
      font-size: 0.75rem;
      padding: 0.5rem;
    }
  }
</style>